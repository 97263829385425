<template>
    <b-form
        @submit.prevent="onSubmit"
        class="form-sm"
    >
        <div class="row">
            <div class="col-12 col-md-5">
                <form-input
                    v-model="street"
                    :id="'headquartersAddress.street'"
                    :label="$t('headquartersAddress.street')"
                    :validation="$v.street"
                />
            </div>
            <div class="col-12 col-md-3">
                <form-input
                    v-model="houseNumber"
                    :id="'headquartersAddress.houseNumber'"
                    :label="$t('headquartersAddress.houseNumber')"
                    :validation="$v.houseNumber"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-5">
                <form-input
                    v-model="city"
                    :id="'headquartersAddress.city'"
                    :label="$t('headquartersAddress.city')"
                    :validation="$v.city"
                />
            </div>
            <div class="col-12 col-md-3">
                <form-input
                    v-model="zip"
                    :id="'headquartersAddress.zip'"
                    :label="$t('headquartersAddress.zip')"
                    :validation="$v.zip"
                />
            </div>
            <div class="col-12 col-md-8">
                <form-input
                    v-model="region"
                    :id="'headquartersAddress.region'"
                    :label="$t('headquartersAddress.region')"
                />
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 col-md-4">
                <form-input-select
                    v-model="country"
                    :id="'headquartersAddress.country'"
                    :label="$t('headquartersAddress.country')"
                    :validation="$v.country"
                    :options="countryOptions"
                />
            </div>
            <div class="col-12 col-md-4">
                <form-input-select
                    v-model="timeZone"
                    :id="'headquartersAddress.timeZone'"
                    :label="$t('headquartersAddress.timeZone')"
                    :validation="$v.timeZone"
                    :options="timeZoneOptions"
                    :disabled="!country"
                />
            </div>
        </div>

        <div class="mt-4 text-right">
            <aspena-btn
                class="mr-3"
                variant="aspena-blue"
                @click.prevent="onCancel"
            >
                {{$t('headquartersAddress.cancel')}}
            </aspena-btn>

            <aspena-btn
                class="mr-3"
                variant="aspena-orange"
                type="submit"
            >
                {{$t('headquartersAddress.submit')}}
            </aspena-btn>
        </div>
    </b-form>
</template>

<script>
import { Address } from '../../../DTO'
import { required } from 'vuelidate/lib/validators'
import countriesAndTimezones from 'countries-and-timezones'

export default {
    name: 'AddressForm',
    data () {
        return {
            ...((new Address(this.$store.state.userData.address || {})).toObject())
        }
    },
    watch: {
        country (newValue, oldValue) {
            if (oldValue && newValue && oldValue !== newValue) {
                this.timeZone = null
            }
        }
    },
    computed: {
        countryOptions () {
            const countries = Object.values(countriesAndTimezones.getAllCountries())
            const options = countries.map(item => { return { value: item.id, text: this.$t(`countries.${item.id}`) } })
            options.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
            return options
        },
        timeZoneOptions () {
            if (this.country) {
                return countriesAndTimezones.getTimezonesForCountry(this.country)
                    .map((timezone) => {
                        return {
                            value: timezone.name,
                            text: `UTC${timezone.utcOffsetStr} ${timezone.name}`
                        }
                    })
            }
            return []
        }
    },
    methods: {
        onSubmit () {
            if (this.$v.$invalid) {
                this.$notify.error(this.$t('headquartersAddress.formInvalidMessage'))
                return this.$v.$touch()
            }
            const data = new Address(JSON.parse(JSON.stringify(this.$data)))
            this.$store.commit('mergeData', data)
            this.$emit('submit', data)
        },
        onCancel () {
            this.$emit('cancel')
        }
    },
    validations: {
        street: {
            required
        },
        houseNumber: {
            required
        },
        city: {
            required
        },
        zip: {
            required
        },
        country: {
            required
        },
        timeZone: {
            required
        }
    }
}
</script>
