<template>
    <div class="container">
        <slot name="registrationType"></slot>
        <h1 class="mt-0 mb-5">{{$t((this.type === 'person') ? 'headquartersAddress.personTitle' : 'headquartersAddress.companyTitle')}}</h1>
        <address-form
            @submit="onSubmit"
            @cancel="onCancel"
        />
    </div>
</template>

<script>
import AddressForm from './Components/AddressForm.vue'

export default {
    name: 'HeadquartersAddressPage',
    components: {
        AddressForm
    },
    computed: {
        type () {
            return this.$store.state.userData.basicData.type
        }
    },
    mounted () {
        this.$nextTick(() => {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 200)
        })
    },
    methods: {
        onSubmit () {
            this.$router.push({ name: 'services' })
        },
        onCancel () {
            this.$router.push(
                (
                    (this.type === 'person')
                        ? { name: 'basicData', params: { type: this.type } }
                        : { name: 'contactPerson' }
                )
            )
        }
    }
}
</script>
